(function() {
  var hamburger = {
	navToggle: document.querySelector('#navToggle'),
	nav: document.querySelector('#menu'),
	doToggle: function(e) {
//	  e.preventDefault();
	  this.navToggle.classList.toggle('is-active');
	  this.nav.classList.toggle('active');
	}
  };
  hamburger.navToggle.addEventListener('click', function(e) { hamburger.doToggle(e); });
  hamburger.nav.addEventListener('click', function(e) { hamburger.doToggle(e); });
}());

const swiper = new Swiper('.swiper', {
  loop: false,
  // If we need pagination
  pagination: {
	el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
	nextEl: '.swiper-button-next',
	prevEl: '.swiper-button-prev',
  },

});

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */

var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos ||  window.pageYOffset < 50) {
    document.getElementById("header").style.top = "0";
  } else {
    document.getElementById("header").style.top = "-145px";
  }
  prevScrollpos = currentScrollPos;
}